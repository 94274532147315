<div style="margin: auto;">
    <div class="submit-form">
      <div *ngIf="!submitted">
        <div class="form-group">
          <label for="title">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            required
            [(ngModel)]="article.title"
            name="title"
          />
        </div>
  
        <div class="form-group">
          <label for="description">Description</label>
          <input
            type="text"
            class="form-control"
            id="description"
            required
            [(ngModel)]="article.description"
            name="description"
          />
        </div>

        <div class="form-group">
          <label for="language">Language</label>
          <input
            type="text"
            class="form-control"
            id="language"
            required
            [(ngModel)]="article.language"
            name="language"
          />
        </div>

        <div class="form-group">
          <label for="description">Image</label>
          <input
            type="text"
            class="form-control"
            id="image"
            [(ngModel)]="article.image"
            name="image"
          />
        </div>

        <div class="form-group">
          <editor
            id="text"
            name="text"
            [(ngModel)]="article.text"
            [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
            }"
          ></editor>
        </div>

        <div class="form-group">
          <label for="text">Group</label>
          <select 
            id="group"
            class="form-control"
            name="group"
            required
            [(ngModel)]="article.group">
            <option value="" disabled>Choose article group</option>
            <option *ngFor="let group of groups" [ngValue]="group">{{group.name}}</option>
         </select>
        </div>

        <button (click)="saveArticle()" class="btn btn-success">Submit</button>
      </div>
  
      <div *ngIf="submitted">
        <h4>You submitted successfully!</h4>
        <button class="btn btn-success" (click)="newArticle()">Add</button>
      </div>
    </div>
  </div>
  