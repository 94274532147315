<div style="margin: auto;">
    <div *ngIf="currentArticle" class="edit-form">
      <h4>Article</h4>
      <form>
        <div class="form-group">
          <label for="title">Title</label>
          <input
            type="text"
            class="form-control"
            id="title"
            [(ngModel)]="currentArticle.title"
            name="title"
          />
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input
            type="text"
            class="form-control"
            id="description"
            [(ngModel)]="currentArticle.description"
            name="description"
          />
        </div>
        <div class="form-group">
          <label for="description">Image</label>
          <input
            type="text"
            class="form-control"
            id="image"
            [(ngModel)]="currentArticle.image"
            name="image"
          />
        </div>
        <div class="form-group">
          <editor
            id="text"
            name="text"
            [(ngModel)]="currentArticle.text"
            [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help'
            }"
          ></editor>
        </div>

        <div class="form-group">
          <label for="group">Group</label>
          <select 
            id="group"
            class="form-control"
            name="group"
            required
            [(ngModel)]="currentArticle.group">
            <option value="" disabled>Choose article group</option>
            <option *ngFor="let group of groups" [ngValue]="group">{{group.name}}</option>
         </select>
        </div>

        <div class="form-group">
          <label><strong>Status:</strong></label>
          {{ currentArticle.published ? "Published" : "Pending" }}
        </div>
      </form>
  
      <button
        class="badge badge-primary mr-2"
        *ngIf="currentArticle.published"
        (click)="updatePublished(false)"
      >
        UnPublish
      </button>
      <button
        *ngIf="!currentArticle.published"
        class="badge badge-primary mr-2"
        (click)="updatePublished(true)"
      >
        Publish
      </button>
  
      <button class="badge badge-danger mr-2" (click)="deleteArticle()">
        Delete
      </button>
  
      <button
        type="submit"
        class="badge badge-success"
        (click)="updateArticle()"
      >
        Update
      </button>
      <p>{{ message }}</p>
    </div>
  
    <div *ngIf="!currentArticle">
      <br />
      <p>Cannot access this Article...</p>
    </div>
  </div>
  