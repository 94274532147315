<div class="row row-cols-1 row-cols-md-3 g-4">
  <div class="col" *ngFor="let article of articles[(language | async) || 'us']; let i = index">
    <mdb-card class="mb-4">
      <div class="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
        <img
          src="{{ article.image }}"
          class="img-fluid"
        />
        <a href="#!">
          <div class="mask" style="background-color: rgba(251, 251, 251, 0.15)"></div>
        </a>
      </div>
      <mdb-card-body>
        <h5 class="card-title">{{ article.title }}</h5>
        <p class="card-text">{{ article.description }}</p>
        <a class="btn btn-primary" routerLink="/articles/{{ article.id }}">Read Article</a>
      </mdb-card-body>
    </mdb-card>
  </div>
</div>
<!---
<mdb-card *ngFor="let article of articles; let i = index">
  <mdb-card-body>
    <mdb-card-title>
      <h4>{{ article.title }}</h4>
    </mdb-card-title>
    <mdb-card-text>{{ article.description }}</mdb-card-text>
    <div class="flex-row">
      <a class="card-link">Card link</a>
      <a class="card-link">Another link</a>
    </div>
  </mdb-card-body>
</mdb-card>
-->